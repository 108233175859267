exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bespoke-js": () => import("./../../../src/pages/bespoke.js" /* webpackChunkName: "component---src-pages-bespoke-js" */),
  "component---src-pages-bullion-js": () => import("./../../../src/pages/bullion.js" /* webpackChunkName: "component---src-pages-bullion-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-delivery-js": () => import("./../../../src/pages/delivery.js" /* webpackChunkName: "component---src-pages-delivery-js" */),
  "component---src-pages-gold-jewellery-bangles-index-js": () => import("./../../../src/pages/gold-jewellery/bangles/index.js" /* webpackChunkName: "component---src-pages-gold-jewellery-bangles-index-js" */),
  "component---src-pages-gold-jewellery-bracelets-index-js": () => import("./../../../src/pages/gold-jewellery/bracelets/index.js" /* webpackChunkName: "component---src-pages-gold-jewellery-bracelets-index-js" */),
  "component---src-pages-gold-jewellery-brooches-index-js": () => import("./../../../src/pages/gold-jewellery/brooches/index.js" /* webpackChunkName: "component---src-pages-gold-jewellery-brooches-index-js" */),
  "component---src-pages-gold-jewellery-chains-index-js": () => import("./../../../src/pages/gold-jewellery/chains/index.js" /* webpackChunkName: "component---src-pages-gold-jewellery-chains-index-js" */),
  "component---src-pages-gold-jewellery-cufflinks-index-js": () => import("./../../../src/pages/gold-jewellery/cufflinks/index.js" /* webpackChunkName: "component---src-pages-gold-jewellery-cufflinks-index-js" */),
  "component---src-pages-gold-jewellery-earrings-index-js": () => import("./../../../src/pages/gold-jewellery/earrings/index.js" /* webpackChunkName: "component---src-pages-gold-jewellery-earrings-index-js" */),
  "component---src-pages-gold-jewellery-index-js": () => import("./../../../src/pages/gold-jewellery/index.js" /* webpackChunkName: "component---src-pages-gold-jewellery-index-js" */),
  "component---src-pages-gold-jewellery-necklaces-index-js": () => import("./../../../src/pages/gold-jewellery/necklaces/index.js" /* webpackChunkName: "component---src-pages-gold-jewellery-necklaces-index-js" */),
  "component---src-pages-gold-jewellery-pendants-index-js": () => import("./../../../src/pages/gold-jewellery/pendants/index.js" /* webpackChunkName: "component---src-pages-gold-jewellery-pendants-index-js" */),
  "component---src-pages-gold-jewellery-rings-index-js": () => import("./../../../src/pages/gold-jewellery/rings/index.js" /* webpackChunkName: "component---src-pages-gold-jewellery-rings-index-js" */),
  "component---src-pages-guernsey-granite-jewellery-bracelets-index-js": () => import("./../../../src/pages/guernsey-granite-jewellery/bracelets/index.js" /* webpackChunkName: "component---src-pages-guernsey-granite-jewellery-bracelets-index-js" */),
  "component---src-pages-guernsey-granite-jewellery-earrings-index-js": () => import("./../../../src/pages/guernsey-granite-jewellery/earrings/index.js" /* webpackChunkName: "component---src-pages-guernsey-granite-jewellery-earrings-index-js" */),
  "component---src-pages-guernsey-granite-jewellery-index-js": () => import("./../../../src/pages/guernsey-granite-jewellery/index.js" /* webpackChunkName: "component---src-pages-guernsey-granite-jewellery-index-js" */),
  "component---src-pages-guernsey-granite-jewellery-pendants-index-js": () => import("./../../../src/pages/guernsey-granite-jewellery/pendants/index.js" /* webpackChunkName: "component---src-pages-guernsey-granite-jewellery-pendants-index-js" */),
  "component---src-pages-guernsey-granite-jewellery-rings-index-js": () => import("./../../../src/pages/guernsey-granite-jewellery/rings/index.js" /* webpackChunkName: "component---src-pages-guernsey-granite-jewellery-rings-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pewter-js": () => import("./../../../src/pages/pewter.js" /* webpackChunkName: "component---src-pages-pewter-js" */),
  "component---src-pages-platinum-jewellery-index-js": () => import("./../../../src/pages/platinum-jewellery/index.js" /* webpackChunkName: "component---src-pages-platinum-jewellery-index-js" */),
  "component---src-pages-platinum-jewellery-rings-index-js": () => import("./../../../src/pages/platinum-jewellery/rings/index.js" /* webpackChunkName: "component---src-pages-platinum-jewellery-rings-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-shopify-product-id-js": () => import("./../../../src/pages/product/{ShopifyProduct.id}.js" /* webpackChunkName: "component---src-pages-product-shopify-product-id-js" */),
  "component---src-pages-returns-js": () => import("./../../../src/pages/returns.js" /* webpackChunkName: "component---src-pages-returns-js" */),
  "component---src-pages-royal-coronation-spoon-js": () => import("./../../../src/pages/royal-coronation-spoon.js" /* webpackChunkName: "component---src-pages-royal-coronation-spoon-js" */),
  "component---src-pages-silver-jewellery-bangles-index-js": () => import("./../../../src/pages/silver-jewellery/bangles/index.js" /* webpackChunkName: "component---src-pages-silver-jewellery-bangles-index-js" */),
  "component---src-pages-silver-jewellery-bracelets-index-js": () => import("./../../../src/pages/silver-jewellery/bracelets/index.js" /* webpackChunkName: "component---src-pages-silver-jewellery-bracelets-index-js" */),
  "component---src-pages-silver-jewellery-brooches-index-js": () => import("./../../../src/pages/silver-jewellery/brooches/index.js" /* webpackChunkName: "component---src-pages-silver-jewellery-brooches-index-js" */),
  "component---src-pages-silver-jewellery-chains-index-js": () => import("./../../../src/pages/silver-jewellery/chains/index.js" /* webpackChunkName: "component---src-pages-silver-jewellery-chains-index-js" */),
  "component---src-pages-silver-jewellery-cufflinks-index-js": () => import("./../../../src/pages/silver-jewellery/cufflinks/index.js" /* webpackChunkName: "component---src-pages-silver-jewellery-cufflinks-index-js" */),
  "component---src-pages-silver-jewellery-earrings-index-js": () => import("./../../../src/pages/silver-jewellery/earrings/index.js" /* webpackChunkName: "component---src-pages-silver-jewellery-earrings-index-js" */),
  "component---src-pages-silver-jewellery-index-js": () => import("./../../../src/pages/silver-jewellery/index.js" /* webpackChunkName: "component---src-pages-silver-jewellery-index-js" */),
  "component---src-pages-silver-jewellery-necklaces-index-js": () => import("./../../../src/pages/silver-jewellery/necklaces/index.js" /* webpackChunkName: "component---src-pages-silver-jewellery-necklaces-index-js" */),
  "component---src-pages-silver-jewellery-pendants-index-js": () => import("./../../../src/pages/silver-jewellery/pendants/index.js" /* webpackChunkName: "component---src-pages-silver-jewellery-pendants-index-js" */),
  "component---src-pages-silver-jewellery-rings-index-js": () => import("./../../../src/pages/silver-jewellery/rings/index.js" /* webpackChunkName: "component---src-pages-silver-jewellery-rings-index-js" */),
  "component---src-pages-silverware-js": () => import("./../../../src/pages/silverware.js" /* webpackChunkName: "component---src-pages-silverware-js" */)
}

